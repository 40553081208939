
.control
  display: flex
  align-items: center
  text-align: center
  margin-bottom: 20px
  justify-content: center
  flex-wrap: wrap
  div, input
    margin: 10px

.btn
  position: relative
  display: flex
  align-items: center
  justify-content: center
  width: 80px
  height: 30px
  border-radius: 5px
  font-size: 12px
  color: #fff
  background: #0eaee2
  cursor: pointer

.download
  text-decoration: none

.upload_btn input
  opacity: 0
  pointer-events: none

.select
  margin-right: 20px
  width: 80px
  height: 30px
  padding: 0 5px
  border-radius: 5px
  border: 1px solid #ccc

select
  height: 100%
  outline: none
  border: none
  background: transparent

.img_name
  min-width: 100px
  margin-right: 20px
  text-overflow: ellipsis
  max-width: 30px
  overflow: hidden
  white-space: nowrap

.wrap
  text-align: center
  width: 100%
  max-width: 600px
  margin: 0 auto

.upload_tips
  width: 100%
  max-width: 250px
  height: 250px
  background-color: #dddddd
  margin: 0 auto
  display: flex
  align-items: center
  justify-content: center
  border-radius: 10px
  user-select: none
  overflow: hidden
  position: relative
  img
    max-width: 100%
    margin: 0 auto
  span
    word-break: break-word
    width: 90%
    margin: 0 auto
  input
    position: absolute
    opacity: 0
    left: 0
    top: 0
    width: 100%
    height: 100%
    display: block
